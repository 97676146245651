@font-face {
    font-family: 'Zighead';
    src: local('Zighead'), url(Components/Assets/Fonts/Zighead.woff) format('woff');
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: #F6F3F4;
    overflow-x: hidden;
    background-color: #dfdadb;
    background-color: #eee9eb;
    background-color: #f2eff1;
}

body .App {
    margin: 0 auto;
}

h1, h2, h3, .title {
    font-family: 'Zighead';
    font-weight: normal;
    color: #70545E;
}

h1 {
    font-size: 140px;
    line-height: 0.7;
}

h2 {
    font-size: 62px;
    line-height: 1.4;
    padding-bottom: 48px;
}

h3 {
    font-size: 48px;
    line-height: 1.2;
}

p, label, input, textarea, button, a, span {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 300;
    color: #70545E;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: #70545E;
}

a:hover {
    border-bottom: 1px solid #70545E;
}

@media (max-width: 1440px) {

    h1 {
        font-size: 90px;
    }

    h2 {
        font-size: 48px;
    }

    h3 {
        font-size: 32px;
    }

    p, input, textarea, button, a, span {
        font-size: 16px;
    }
}

@media (max-width: 768px){
    h1 {
        font-size: 64px;
    }

    h2 {
        font-size: 32px;
        padding-bottom: 32px;
    }

    h3 {
        font-size: 32px;
    }
}

